const ROUTER = {
  SVG_VIEWER: "/svg-viewer",
  HOME: "/",
  ERROR: "/error",
  COMPLETE: "/complete"
  //ANONYMOUS

  // ADMIN



}
export default ROUTER
